<template>
	<div class="page-status">
		<h1 class="mb-3">Is HeyGov working?</h1>

		<p class="mb-2">This page checks if all HeyGov systems work properly for you.</p>

		<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
			<div class="col my-2">
				<div class="card border-success">
					<div class="card-header">
						<h6 class="my-0">✅ HeyGov App</h6>
					</div>
					<div class="card-body">
						<p class="card-text mb-2">
							Version: <strong>{{ version }}</strong>
						</p>
						<p class="card-text">
							Platform: <strong>{{ device.platform }} / {{ device.operatingSystem }}</strong>
						</p>
					</div>
				</div>
			</div>
			<div class="col my-2">
				<div
					class="card"
					:class="{ 'border-danger': apiStatus === 'error', 'border-success': apiStatus === 'ok' }"
				>
					<div class="card-header">
						<h6 class="my-0">
							<span v-if="apiStatus === 'ok'">✅</span
							><span v-else-if="apiStatus === 'error'">🚨</span> HeyGov API
						</h6>
					</div>
					<div class="card-body">
						<p class="card-text mb-2">
							<code>{{ apiUrl }}</code>
						</p>
						<p class="card-text">
							Latency: <strong>{{ apiResponseTime || '-' }} miliseconds</strong>
						</p>
					</div>
				</div>
			</div>
			<div class="col my-2">
				<div
					class="card"
					:class="{ 'border-danger': stripeStatus === 'error', 'border-success': stripeStatus === 'ok' }"
				>
					<div class="card-header">
						<h6 class="my-0">
							<span v-if="stripeStatus === 'ok'">✅</span
							><span v-else-if="stripeStatus === 'error'">🚨</span> Stripe
						</h6>
					</div>
					<div class="card-body">
						<p v-if="stripeStatus === 'error'" class="card-text">
							Your device can't connect to the Stripe network to process payments. Make sure the
							<a href="https://stripe.com/docs/ips" target="_blank"
								>full list of Stripe domains &amp; IPs</a
							>
							is whitelisted on your network.
						</p>
						<p v-else class="card-text">
							Stripe status: <code>{{ stripeStatus }}</code>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { version } from '@/../package.json'

import heyGovApi from '@/api.js'

export default {
	name: 'About',
	data() {
		return {
			version,

			apiResponseTime: null,
			apiStatus: 'unknown',

			stripeStatus: 'loading',
		}
	},
	computed: {
		...mapState(['apiUrl', 'device']),
		hasStripe() {
			return Boolean(window.Stripe)
		},
	},
	created() {
		this.testHeyGovApi()
	},
	mounted() {
		if ('Stripe' in window) {
			this.stripeStatus = 'ok'
		} else {
			let StripeScript = document.createElement('script')
			StripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
			StripeScript.onload = event => {
				console.log('stripe load', event)
				this.stripeStatus = 'ok'
			}
			StripeScript.onerror = event => {
				console.warn('stripe error', event)
				Vue.toasted.error(`Stripe load error`)
				this.stripeStatus = 'error'
			}
			document.head.appendChild(StripeScript)
		}
	},
	methods: {
		testHeyGovApi() {
			this.timeStart = performance.now()

			heyGovApi.get('/').then(
				() => {
					this.apiResponseTime = performance.now() - this.timeStart
					this.apiStatus = 'ok'
				},
				error => {
					Vue.toasted.error(`API error: ${error.message}`)
					this.apiStatus = 'error'
				}
			)
		},
	},
}
</script>
